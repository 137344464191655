<template>
    <div>
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="table">
            <div class="table-item">
                <span>真实姓名</span>
                <input type="text" placeholder="请输入真实姓名">
            </div>
            <div class="table-item">
                <span>身份证号</span>
                <input type="text" placeholder="请输入身份证号">
            </div>
        </div>
        <div class="btn">
            <button>确定</button>
        </div>
        <div class="desc">
            <p>【重要】：请填写自己真实的信息，一旦绑定便无法更改，若借用他人信息操作下，将导致账号封号，不可提现，平台概不负责，详情请见合同及协议</p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .table{
        width: 92%;
        margin: 10px auto;
        height: 80px;
        .table-item{
            padding: 6px 0;
            span{
                font-size: 16px;
                display: inline-block;
                margin-right: 6px;
            }
            input{
                border: none;
                background-color: rgba(255, 255, 255, 0);
                font-size: 14px;
                cursor: pointer;
                color: rgb(233, 224, 111);
            }
        }
    }
    .btn{
        width: 100%;
        height: 40px;
        text-align: center;

        button{
            width: 60%;
            height: 40px;
            border: none;
            background-color: #d25555;
            border-radius: 6px;
            font-size: 16px;
            color: #fff;
            margin: 12px 0;
            box-shadow: 0px 2px 2px 0px rgba(210, 85, 85,0.5);

        }
    }
    .desc{
        p{
            color: #d25555;
            font-size: 14px;
            margin-top: 50px;
            padding: 0 10px;
        }
        
    }
</style>